import { useTranslation } from "react-i18next";
import NavBar from "../NavBar";
import Footer from "../Footer";

const Terms = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white">
    <NavBar />
    <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
      <div className="relative isolate overflow-hidden px-6 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
        <div className="mx-auto text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left pt-4">
            <h1 className="text-center text-3xl font-bold">
              {t(`terms.title`)}
            </h1>
            <h2 className="text-xl font-bold">{t(`terms.introTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`terms.intro`)}
            </p>
            <h2 className="text-xl font-bold">{t(`terms.acceptTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`terms.accept`)}
            </p>
            <h2 className="text-xl font-bold">{t(`terms.registerTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`terms.register`)}
            </p>
            <h2 className="text-xl font-bold">{t(`terms.platformTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`terms.platform`)}
            </p>
            <h2 className="text-xl font-bold">{t(`terms.obligationsTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`terms.obligations`)}
            </p>
            <h2 className="text-xl font-bold">
              {t(`terms.responsibilityTitle`)}
            </h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`terms.responsibility`)}
            </p>
            <h2 className="text-xl font-bold">
              {t(`terms.intellectualPropertyTitle`)}
            </h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`terms.intellectualProperty`)}
            </p>
            <h2 className="text-xl font-bold">{t(`terms.linksTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`terms.links`)}
            </p>
            <h2 className="text-xl font-bold">{t(`terms.changesTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`terms.changes`)}
            </p>
            <h2 className="text-xl font-bold">{t(`terms.finishTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`terms.finish`)}
            </p>
            <h2 className="text-xl font-bold">{t(`terms.lawTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">{t(`terms.law`)}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
