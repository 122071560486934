import NavBar from "../NavBar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import boxImage from "../../resources/box.png";
import checklistImage from "../../resources/checklist.png";
import bundleQrImage from "../../resources/bundle.png";
import incentiveImage from "../../resources/incentive.png";
import Footer from "../Footer";

export default function LandingPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="bg-white">
      <NavBar />
      <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden px-6 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left pt-4">
            <h1 className="text-3xl font-bold tracking-tight text-accent sm:text-4xl">
              {t("landing.text1")}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {t("landing.text2")}
              <br />
              <br />
              {t("landing.text3")}
              <br />
              <a
                className="text-blue-500 hover:text-blue-700"
                href="/help"
              >
                {t("landing.link1")}
              </a>
            </p>
            <div className="mt-10 flex pb-4 items-center justify-center gap-x-6 lg:justify-start">
              <Button onClick={() => navigate("/bundle/create")}>
                {t("landing.createButton")}
              </Button>
            </div>
          </div>
          <div className="flex flex-col max-w-sm mx-auto justify-center mb-4">
            <img
              className="object-contain md:flex sm:mx-12"
              src={boxImage}
              alt="QR"
            />
          </div>
        </div>
        <div className="relative isolate overflow-hidden px-6 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0 mt-9">
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left pt-4">
            <h1 className="text-3xl font-bold tracking-tight text-accent sm:text-4xl">
              {t("landing.text4")}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {t("landing.text5")}
              <br />
              {t("landing.text6")}
            </p>
          </div>
          <div className="flex flex-col max-w-sm mx-auto justify-center mb-4">
            <img
              className="object-contain md:flex sm:mx-12"
              src={checklistImage}
              alt="box"
            />
          </div>
        </div>
        <div className="relative isolate overflow-hidden px-6 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0 mt-9">
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left pt-4">
            <h1 className="text-3xl font-bold tracking-tight text-accent sm:text-4xl">
              {t("landing.text7")}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {t("landing.text8")}
              <br />
              {t("landing.text9")}
            </p>
          </div>
          <div className="flex flex-col max-w-sm mx-auto justify-center mb-4">
            <img
              className="object-contain md:flex sm:mx-12"
              src={bundleQrImage}
              alt="QR"
            />
          </div>
        </div>
        <div className="relative isolate overflow-hidden px-6 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0 mt-9">
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left pt-4">
            <h1 className="text-3xl font-bold tracking-tight text-accent sm:text-4xl">
              {t("landing.text10")}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {t("landing.text11")}
              <br />
              {t("landing.text12")}
              <br />
              {t("landing.text13")}
              <br />
              <br />
              {t("landing.text14")}
            </p>
            <div className="mt-10 flex pb-4 items-center justify-center gap-x-6 lg:justify-start">
              <Button onClick={() => navigate("/bundle/create")}>
                {t("landing.signUpButton")}
              </Button>
            </div>
          </div>
          <div className="flex flex-col max-w-sm mx-auto justify-center mb-4">
            <img
              className="object-contain md:flex sm:mx-12"
              src={incentiveImage}
              alt="QR"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
