import { useTranslation } from "react-i18next";
import NavBar from "../NavBar";
import Footer from "../Footer";

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white">
    <NavBar />
    <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
      <div className="relative isolate overflow-hidden px-6 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
        <div className="mx-auto text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left pt-4">
            <h1 className="text-center text-3xl font-bold">
              {t(`privacy.title`)}
            </h1>
            <p className="mt-4 mb-4 text-zinc-500 dark:text-zinc-400">
              {t(`privacy.description`)}
            </p>
            <h2 className="text-xl font-bold">{t(`privacy.infoTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`privacy.info`)}{" "}
              <a href="https://sendgrid.com/policies/privacy/">
                {t(`privacy.here`)}
              </a>
              .
            </p>
            <h2 className="text-xl font-bold">
              {t(`privacy.protectInfoTitle`)}
            </h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`privacy.protectInfo`)}
            </p>
            <h2 className="text-xl font-bold">{t(`privacy.optionTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`privacy.option`)}
            </p>
            <h2 className="text-xl font-bold">{t(`privacy.trackingTitle`)}</h2>
            <p className="text-zinc-500 dark:text-zinc-400">
              {t(`privacy.tracking`)}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
