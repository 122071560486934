import NavBar from "../NavBar";
import { useTranslation } from "react-i18next";
import { useForm, ValidationError } from "@formspree/react";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Send } from "lucide-react";
import YouTube from "react-youtube";
import { useMediaQuery } from "react-responsive";
import questions from "./questions.json";
import Question from "./Question";
import Footer from "../Footer";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export default function HelpPage() {
  const { t } = useTranslation();
  const [state, handleSubmit] = useForm("xanwybve");
  const [ready, setReady] = useState<boolean>(false);
  const isSmallDevice = useMediaQuery({ maxWidth: 768 });
  const options = {
    height: isSmallDevice ? "280" : "470",
    width: "100%",
    playerVars: {
      autoplay: 1,
      controls: 1,
    },
  };
  const handleOnReady = (event: any) => {
    setReady(true);
    event.target.pauseVideo();
  };
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [ready, location.hash]);

  return (
    <div className="bg-white">
      <NavBar />
      <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden px-6 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <div className="mx-auto text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left pt-4">
            <h1 className="text-3xl font-bold tracking-tight text-accent sm:text-4xl">
              {t("help.title")}
            </h1>
            <h2 className="mt-3 text-xl font-bold tracking-tight text-accent sm:text-2xl">
              {t("help.tutorials")}
            </h2>
            <YouTube
              className="mt-3 mb-3 mr-8 ml-8"
              videoId="XJw_xoeOD2o"
              opts={options}
              onReady={handleOnReady}
              id="intro-video"
            />
            <YouTube
              className="mt-3 mb-3 mr-8 ml-8"
              videoId="KqDEIsbpemo"
              opts={options}
              onReady={handleOnReady}
              id="stock-1-video"
            />
            <YouTube
              className="mt-3 mb-3 mr-8 ml-8"
              videoId="Hw8EzUgOvzo"
              opts={options}
              onReady={handleOnReady}
              id="stock-2-video"
            />
            <h2 id="faqs-section" className="mt-6 text-xl font-bold tracking-tight text-accent sm:text-2xl">
              {t("help.faqs")}
            </h2>
            {questions.map((question) => (
              <Question
                key={`question-${question.title}`}
                question={question}
              />
            ))}
            <h2 id="contact-section" className="mt-6 text-xl font-bold tracking-tight text-accent sm:text-2xl">
              {t("help.contactTitle")}
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600 text-left">
              {t("help.contactDescription")}
            </p>
            <div className="mt-10 flex pb-4 items-center justify-center gap-x-6 lg:justify-start">
              {state.succeeded && (
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  {t("help.contactCongrats")}
                </p>
              )}
              {!state.succeeded && (
                <form
                  onSubmit={handleSubmit}
                  className="w-full flex items-center justify-center flex-col ml-1/3 mr-1/3"
                >
                  <Label htmlFor="name" className="mb-2 self-start">
                    {t("help.contactName")}
                  </Label>
                  <Input id="name" type="text" required name="name" />
                  <Label htmlFor="store" className="mb-2 mt-2 self-start">
                    {t("help.store")}
                  </Label>
                  <Input id="store" type="text" required name="store" />
                  <Label htmlFor="email" className="mb-2 mt-2 self-start">
                    {t("help.email")}
                  </Label>
                  <Input
                    id="email"
                    type="email"
                    required
                    placeholder="mail@example.com"
                    name="email"
                    className="mb-4"
                  />
                  <ValidationError
                    prefix="Email"
                    field="email"
                    errors={state.errors}
                  />
                  <textarea
                    className="mb-4 w-full h-40 border-2 border-grey-500 rounded-md"
                    id="message"
                    name="message"
                    required
                    placeholder={" " + t("help.contactMessage")}
                  />
                  <ValidationError
                    prefix="Message"
                    field="message"
                    errors={state.errors}
                  />
                  <Button
                    className="self-end text-white"
                    type="submit"
                    disabled={state.submitting}
                  >
                    <div className="flex items-center justify-center">
                      {state.submitting ? (
                        <div className="animate-spin h-6 w-6 rounded-full border-t-4 border-gray-900 mr-2" />
                      ) : (
                        <Send size="16" className="mr-2" />
                      )}
                      {t("help.submit")}
                    </div>
                  </Button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
