import { useTranslation } from "react-i18next";
import NavBar from "../NavBar";
import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";


const Plans = () => {
  const { t } = useTranslation();
  const plans = [
    {
      name: 'FREE',
      originalPrice: '-',
      discountPrice: 'USD $0.00',
      benefits: [t(`plans.free1`), t(`plans.free2`), t(`plans.free3`)],
      cta: false,
      ctaLabel: t(`plans.freeCtaLabel`)
    },
    {
      name: 'STARTER',
      originalPrice: 'USD $10.00',
      discountPrice: 'USD $7.00',
      benefits: [t(`plans.free1`), t(`plans.starter2`), t(`plans.starter3`)],
      cta: true,
      ctaLabel: t(`plans.starterCtaLabel`)
    },
    {
      name: 'ENTERPRISE',
      originalPrice: '-',
      discountPrice: 'Consultar',
      benefits: [t(`plans.enterprise1`), t(`plans.free2`), t(`plans.free3`)],
      cta: true,
      ctaLabel: t(`plans.starterCtaLabel`)
    }
  ];
  const navigate = useNavigate();


  return (

    <div className="bg-white">
    <NavBar />
    <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
      <div className="relative isolate overflow-hidden px-6 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
        <div className="mx-auto text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left pt-4">
        <h1 className="text-center text-3xl font-bold">
              {t(`plans.title`)}
            </h1>
            <p className="text-zinc-500 dark:text-zinc-400 mt-10">
              {t(`plans.intro`)}
            </p>
            <h2 className="text-xl font-bold mt-10 mb-10">
              Nuestros planes
            </h2>
            <Container maxWidth="md">
              <Grid container spacing={3}>
                {plans.map((plan) => (
                  <Grid item xs={12} sm={4} key={plan.name}>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" component="h2" sx={{ textAlign: "center", marginBottom: '4%' }}>
                          {plan.name}
                        </Typography>
                        <Typography variant="body2" sx={{ textAlign: "center", textDecoration: 'line-through' }} color="textSecondary">
                          {plan.originalPrice}
                        </Typography>
                        <Typography variant="body1" component="h2" sx={{ fontWeight: 'bold', textAlign: "center", marginBottom: '4%' }}>
                          {plan.discountPrice}
                        </Typography>
                        <ul>
                          {plan.benefits.map((benefit) => (
                            <li key={benefit}>{benefit}</li>
                          ))}
                        </ul>
                        <Button disabled={!plan.cta} className="w-full text-white mt-3" onClick={()=>navigate("/help#contact-section")}>{plan.ctaLabel}</Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Container>
            <p className="text-zinc-500 dark:text-zinc-400 mb-30 mt-10">
              {t(`plans.payment`)}
            </p>
        </div>
      </div>
    </div>
    <Footer />
    </div>
    
  );
};

export default Plans;
